function dropdown() {
    const dropdowns = document.querySelectorAll('.dropdown');

    if (!dropdowns) {
        return;
    }

    for (let i = 0; i < dropdowns.length; i++) {
        dropdowns[i].addEventListener('click', (e) => {
            let dropdownMenu = dropdowns[i].querySelector('.dropdown-menu');
            dropdownMenu.classList.toggle('hidden');
        });
    }
}

dropdown();
