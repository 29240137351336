(function() {
    const progressBar = document.getElementById('progressbar');

    if (!progressBar) {
        return;
    }

    window.addEventListener('scroll', function(e) {
        const s = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        const body = document.body;
        const html = document.documentElement;
        const d = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const c = window.innerHeight;
        const position = (s / (d - c)) * 100;
        progressBar.style.width = `${position}%`;
    });
})();