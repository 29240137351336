import Glide, { Controls, Autoplay, Swipe } from '@glidejs/glide/dist/glide.modular.esm'

function Slider() {
    const el = document.querySelector('.glide');

    if (!el) return;

    setGlideHeight(el);

    let timeOut;
    window.addEventListener('resize', () => {
        if (timeOut) {
            clearTimeout(timeOut);
        }
        timeOut = setTimeout(() => setGlideHeight(el), 200);
    });

    const glide = new Glide(el, {
        type: 'carousel',
        perView: 1,
        autoplay: 5000,
        navigation: true,
        navigationCenter: true,
    });

    glide.on('mount.before', function (e) {
        const slides = el.querySelectorAll('.glide__slide').length;
        const controls = el.querySelector('.glide__bullets');
        let html = '';
        for(let i = 0; i < slides; i++) {
            html +=
                `<div class="glide__bullet" data-glide-dir="=${i}">
                    <svg class="progress-circle" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68">
                        <path d="M34,1.5C52,1.5,66.5,16,66.5,34S52,66.5,34,66.5S1.5,52,1.5,34S16,1.5,34,1.5z"/>
                    </svg>
                </div>`;
        }
        controls.innerHTML = html;
    });

    glide.mount({Controls, Autoplay, Swipe});
}

function setGlideHeight(el) {
    let heroImage = document.querySelector('.hero-image-holder-home');
    el.style.height = heroImage.offsetHeight + 'px';
}

Slider();

